@font-face {
	font-family: "custom-icons";
	src: url("custom-icons.woff2?5cc89971e1bdb9e0ab3917a990c8a7c4") format("woff2"),
url("custom-icons.woff?5cc89971e1bdb9e0ab3917a990c8a7c4") format("woff");
}

.icon {
	line-height: 1;
}

.icon:before {
	font-family: custom-icons !important;
	font-style: normal;
	font-weight: normal !important;
	vertical-align: top;
}

.icon-accordion-arrow:before {
	content: "\f101";
}
.icon-agreement-lock:before {
	content: "\f102";
}
.icon-arrow-down:before {
	content: "\f103";
}
.icon-arrow-left:before {
	content: "\f104";
}
.icon-arrow-right:before {
	content: "\f105";
}
.icon-arrow-send:before {
	content: "\f106";
}
.icon-attention:before {
	content: "\f107";
}
.icon-back-btn:before {
	content: "\f108";
}
.icon-check-mark-2:before {
	content: "\f109";
}
.icon-check-mark:before {
	content: "\f10a";
}
.icon-chevron-down:before {
	content: "\f10b";
}
.icon-chevron-up:before {
	content: "\f10c";
}
.icon-circle-objective:before {
	content: "\f10d";
}
.icon-clock:before {
	content: "\f10e";
}
.icon-close-popup:before {
	content: "\f10f";
}
.icon-close:before {
	content: "\f110";
}
.icon-comment:before {
	content: "\f111";
}
.icon-document-alt:before {
	content: "\f112";
}
.icon-document:before {
	content: "\f113";
}
.icon-esc:before {
	content: "\f114";
}
.icon-exclamation-mark:before {
	content: "\f115";
}
.icon-eye-close:before {
	content: "\f116";
}
.icon-eye-open:before {
	content: "\f117";
}
.icon-facebook:before {
	content: "\f118";
}
.icon-folder:before {
	content: "\f119";
}
.icon-google:before {
	content: "\f11a";
}
.icon-hamburger:before {
	content: "\f11b";
}
.icon-hint:before {
	content: "\f11c";
}
.icon-info:before {
	content: "\f11d";
}
.icon-learning-objective:before {
	content: "\f11e";
}
.icon-link:before {
	content: "\f11f";
}
.icon-linkedin:before {
	content: "\f120";
}
.icon-logout:before {
	content: "\f121";
}
.icon-medal:before {
	content: "\f122";
}
.icon-minus:before {
	content: "\f123";
}
.icon-ok:before {
	content: "\f124";
}
.icon-pause:before {
	content: "\f125";
}
.icon-plus-tiny:before {
	content: "\f126";
}
.icon-plus:before {
	content: "\f127";
}
.icon-point:before {
	content: "\f128";
}
.icon-question:before {
	content: "\f129";
}
.icon-repeat:before {
	content: "\f12a";
}
.icon-rounded-info:before {
	content: "\f12b";
}
.icon-survey:before {
	content: "\f12c";
}
.icon-timer:before {
	content: "\f12d";
}
.icon-zoom-bg:before {
	content: "\f12e";
}
.icon-zoom:before {
	content: "\f12f";
}
