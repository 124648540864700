.tippy-popper {
  max-width: none;
  position: absolute;
  top: 0;
  transition: none !important;
  pointer-events: auto;
  z-index: 200 !important;
}

.tippy-popper .tippy-tooltip.light-theme {
  padding: 0;
  border-radius: 0;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.08);
  background: transparent;
}

.tippy-popper .tippy-tooltip.no-transform-theme {
  transform: none !important;
}

.tippy-popper .tippy-tooltip-content {
  font: 400 11px / 1.55 Montserrat, serif;
}
